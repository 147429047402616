import React, { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import baseUrl, { authentication } from "../Utils";
import Dialog from "./dialogs/dialog";
import Table from "./Table";
import Confirm from "./dialogs/Confirm";

export default function ResetBankPoint() {
  const [submitted, setSubmitted] = useState(false);
  const [resetOpen, setResetOpen] = useState(false);

  const resetMarksNow = () => {
    fetch(`${baseUrl}/reset-bank-points`, {
      method: "POST",
      headers: {
        Authorization: authentication,
      },
    }).then(() => {
      setResetOpen(false );
      setSubmitted(true);
      // getMarksResetDates();
    });
  };


  return (
    <>

    <Confirm
          name="Confirm Reset"
          isOpen={resetOpen}
          saveFunction={ resetMarksNow }
          closeFunction={() => {
            setResetOpen(false);
          }}
        >
          Are you sure you want to reset all points? This action cannot be reversed.
        </Confirm>

        <br></br>
        
      <h2 style={{ textAlign: "center", marginBottom: 20 }}>
          {!submitted ? "Reset bank points - YTD Points" : "Reset!"}
      </h2>
        { !submitted  &&
          <div style={{textAlign: "Center"}}>
            <Button onClick={()=>{ setResetOpen(true)}}>Reset Now</Button>
          </div>
        }
    </>
  );
}
