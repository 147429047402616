import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import baseUrl, {
  authentication,
} from "../Utils";

import Button from "react-bootstrap/Button";
import { Form } from "bootstrap-4-react";

export default () => {
    const {id} = useParams();
    const inputdatalistRef = useRef(null);
    const [categories, setCategories] = useState([])
    const [state, setState] = useState({
      quickNote: "",
      largeNote: "",
      consequence: "",
      consequenceFulfilled: false,
      parentsContacted: false,
      studentData: [],      
      studentChosenId: id || '',
      studentDataObject: {},
      studentChosenId: id || '',
      submitting: false,
    })

    useEffect(() => {
      getCategories();
      getStudents();
    }, [])

    const reset = () => {
      if(!id){
        inputdatalistRef.current.value = '';
      }
      setState(
        {
          ...state,
          quickNote: "",
          largeNote: "",
          studentChosenId: id || '',
          consequence: '',
          consequenceFulfilled: false,
          parentsContacted: false,
          submitting: false,
        })
    };

    const save = () => {
      const { studentChosenId, largeNote, quickNote, consequence, consequenceFulfilled, parentsContacted } = state;
      if (!studentChosenId || (!quickNote && !largeNote)) {
        alert('You must select a student and you must enter some sort of note!')
        return
      }
      if (!state.submitting) {
        setState({...state, submitting: true})
        let record = {};
        record.studentId = studentChosenId;
        record.category_id = +quickNote;
        record.incident = largeNote;
        record.consequence = consequence;
        record.consequenceFulfilled = consequenceFulfilled;
        record.parentsContacted = parentsContacted;
        console.log("🚀 ~ file: NewNote.js ~ line 104 ~ NewNote ~ save ~ record", record)
  
        let formData = new FormData();
  
        formData.append("note", JSON.stringify(record));
        fetch(baseUrl + "/principal-note", {
          method: "POST",
          body: formData,
          headers: {
            Authorization: authentication,
          },
        })
          .then((response) => response.text())
  
          .then((text) => {
            if (text == "successful") {
              reset();
              console.log("successful", text)
            } else {
              alert(text);
            }
          });
      }
    }

    const getCategories = () => {
      fetch(`${baseUrl}/categories`, {
        headers: {
          Authorization: authentication,
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((res) => {
          console.log("🚀 ~ file: NewNote.js 102 ~ NewNote ~ .then ~ res", res)
          setCategories(res)
        })
    }
  
    const getStudents = () => {
      fetch(`${baseUrl}/students`, {
        headers: {
          Authorization: authentication,
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((res) => {
          console.log("🚀 ~ file: Notes.js ~ line 160 ~ Notes ~ .then ~ res", res)
  
          setState({ ...state, 
            studentData: res.map(s => ({ value: +s.id, label: `${s.first_name} ${s.last_name} ${s.class}`})), 
            studentDataObject: res.reduce((pre, cur) => ({ ...pre, [`${cur.first_name} ${cur.last_name} ${cur.class}`]: +cur.id }), {}) 
          });
        });
    }

    return (
      <div>
        {!id && (
          <>
            <input 
              ref={inputdatalistRef}
              id="inputdatalist" 
              list="students" 
              placeholder="Select, or type, a Student" 
              onChange={e => {
                setState({ ...state, studentChosenId: state.studentDataObject[e.target.value] });
               } } 
              style={{ width: '30%', margin: '50px 0 0 50px' }} >
            </input>
            <datalist type="search" id="students">
              {state.studentData.map(s => (
                <option key={s.value}>{s.label}</option>
              ))}
            </datalist>
            <button onClick={reset}>Clear</button>
          </>
        )}

        <div style={{ padding: "15px" }}>
          <div
            className="row"
            style={{ textAlign: "center", paddingBottom: "15px" }}
          >
            <div
              className="col-sm-12 col-lg-6"
              style={{ paddingTop: "10px" }}
            ></div>

            <div
              className="col-sm-12 col-lg-6"
              style={{ paddingRight: "30px", paddingBottom: "10px" }}
            >
              <div style={{ paddingRight: "30px", paddingBottom: "10px" }}>
                <Button
                  style={{ float: "right" }}
                  onClick={save}
                >
                  Save
                </Button>
              </div>
            </div>
          </div>

          <div style={{ maxWidth: 500, margin: 30 }}>
            <Form.CustomSelect
              value={state.quickNote}
              onChange={(v) => {
                if (v.target.value)
                  setState({ ...state, quickNote: v.target.value });
              }}
            >
              {categories.map(({ name, id }) => (
                <option value={id} key={id}>
                  {name}
                </option>
              ))}
              {state.quickNote == "" && (
                <option value="">Choose Category</option>
              )}
            </Form.CustomSelect>
          </div>
          <div style={{ margin: 30 }}>
            <textarea
              className="form-control"
              value={state.largeNote}
              rows="5"
              placeholder={"Incident"}
              onChange={(v) => {
                setState({ ...state, largeNote: v.target.value });
              }}
            />
          </div>
          <div style={{ margin: 30 }}>
            <textarea
              className="form-control"
              value={state.consequence}
              rows="5"
              placeholder={"Consequence"}
              onChange={(v) => {
                setState({ ...state, consequence: v.target.value });
              }}
            />
          </div>
          <div style={{ margin: 30 }}>
            Consequence Fulfilled?
            <input type="checkbox" checked={state.consequenceFulfilled} onChange={e => setState({ ...state, consequenceFulfilled: !state.consequenceFulfilled })} />
          </div>
          <div style={{ margin: 30 }}>
            Parents Contacted?
            <input type="checkbox" checked={state.parentsContacted} onChange={e => setState({ ...state, parentsContacted: !state.parentsContacted })} />
          </div>
        </div>
      </div>
    );
}


/*  -----------------------------------------------------
  THIS IS THE OLD VERSION OF THE CLASS - MOVED TO FUNCTION

class NewNote extends React.Component {
  submitting = false;

  constructor(props) {
    super(props);
    this.getAllStudents = props.getStudents;
    const href = window.location.href.split('/');
    this.studentId = href[href.length - 1];
    if(this.getAllStudents){
    this.getStudents()
    }
    this.getCategories()
    this.state = {
      categories: [],
      quickNote: "",
      largeNote: "",
      consequence: "",
      consequenceFulfilled: false,
      parentsContacted: false,
      studentData: [],
      studentDataObject: {},
      studentChosenId: this.getAllStudents ? '' : this.studentId,
    };
  }

  reset = () => {
    if(this.getAllStudents){
      document.getElementById('inputdatalist').value = '';
    }
    this.setState(
      {
        quickNote: "",
        largeNote: "",
        studentChosenId: this.getAllStudents ? '' : this.studentId,
        consequence: '',
        consequenceFulfilled: false,
        parentsContacted: false,
      },
      () => {
        this.submitting = false;
      }
    );
  };

  onFileChange = (event) => {
    // Update the state
    this.setState({ selectedFile: event.target.files[0] });
  };

  onFileUpload = () => {
    // Create an object of formData
    const formData = new FormData();

    // Update the formData object
    formData.append(
      "myFile",
      this.state.selectedFile,
      this.state.selectedFile.name
    );
    // Details of the uploaded file
    console.log(this.state.selectedFile);

    // Request made to the backend api
    // Send formData object
    axios.post("api/uploadfile", formData);
  };

  fileData = () => {
    if (this.state.selectedFile) {
      return (
        <div>
          <h2>File Details:</h2>
          <p>File Name: {this.state.selectedFile.name}</p>
          <p>File Type: {this.state.selectedFile.type}</p>
          <p>
            Last Modified:{" "}
            {this.state.selectedFile.lastModifiedDate.toDateString()}
          </p>
        </div>
      );
    } else {
      return (
        <div>
          <br />
          <h4>Choose before Pressing the Upload button</h4>
        </div>
      );
    }
  };

  save() {
    const { studentChosenId, largeNote, quickNote, consequence, consequenceFulfilled, parentsContacted } = this.state;
    if (!studentChosenId || (!quickNote && !largeNote)) {
      alert('You must select a student and you must enter some sort of note!')
      return
    }
    if (!this.submitting) {
      this.submitting = true;
      let record = {};
      record.studentId = studentChosenId;
      record.category_id = +quickNote;
      record.incident = largeNote;
      record.consequence = consequence;
      record.consequenceFulfilled = consequenceFulfilled;
      record.parentsContacted = parentsContacted;
      console.log("🚀 ~ file: NewNote.js ~ line 104 ~ NewNote ~ save ~ record", record)

      let formData = new FormData();

      formData.append("note", JSON.stringify(record));
      fetch(baseUrl + "/principal-note", {
        method: "POST",
        body: formData,
        headers: {
          Authorization: authentication,
        },
      })
        .then((response) => response.text())

        .then((text) => {
          if (text == "successful") {
            this.reset();
            console.log("successful", text)
          } else {
            alert(text);
          }
        });
    }
  }

  getCategories() {
    fetch(`${baseUrl}/categories`, {
      headers: {
        Authorization: authentication,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        console.log("🚀 ~ file: NewNote.js ~ line 145 ~ NewNote ~ .then ~ res", res)
        this.setState({ categories: res })
      })
  }

  getStudents() {
    fetch(`${baseUrl}/students`, {
      headers: {
        Authorization: authentication,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        console.log("🚀 ~ file: Notes.js ~ line 160 ~ Notes ~ .then ~ res", res)
        res.map((item) => {
          item.id = parseInt(item.id);
          item.points = parseInt(item.points);
        });

        this.setState({ studentData: res.map(s => ({ value: s.id, label: `${s.first_name} ${s.last_name} ${s.class}` })) });

        this.setState({ studentDataObject: res.reduce((pre, cur) => ({ ...pre, [`${cur.first_name} ${cur.last_name} ${cur.class}`]: cur.id }), {}) });
      });
  }

  render() {
    return (
      <div>
        {this.getAllStudents && <><input id="inputdatalist" list="students" placeholder="Select, or type, a Student" onChange={e => { this.setState({ studentChosenId: this.state.studentDataObject[e.target.value] }); console.log('this.state.studentChosenId', this.state.studentDataObject[e.target.value]) }} style={{ width: '30%', margin: '50px 0 0 50px' }} ></input>
        <datalist type="search" id="students">
          {this.state.studentData.map(s => (
            <option key={s.value}>{s.label}</option>
          ))}
        </datalist>
        <button onClick={this.reset}>Clear</button></>}
        { THIS BREADCRUMB WAS COMMENTED OUT <Breadcrumb>
          <Breadcrumb.Item href="../Home">Home</Breadcrumb.Item>
          <Breadcrumb.Item href="javascript:history.back()">
            Student Details
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Student Notes</Breadcrumb.Item>
          <Breadcrumb.Item active>
            {this.props.history.location.state.firstName}{" "}
            {this.props.history.location.state.lastName}
          </Breadcrumb.Item>
        </Breadcrumb> END COMMENT}

        <div style={{ padding: "15px" }}>
          <div
            className="row"
            style={{ textAlign: "center", paddingBottom: "15px" }}
          >
            <div
              className="col-sm-12 col-lg-6"
              style={{ paddingTop: "10px" }}
            ></div>

            <div
              className="col-sm-12 col-lg-6"
              style={{ paddingRight: "30px", paddingBottom: "10px" }}
            >
              <div style={{ paddingRight: "30px", paddingBottom: "10px" }}>
                <Button
                  style={{ float: "right" }}
                  onClick={() => {
                    this.save();
                  }}
                >
                  Save
                </Button>
              </div>
            </div>
          </div>

          <div style={{ maxWidth: 500, margin: 30 }}>
            { THIS FIRST (ONLY FIRST - WITH TERNARY) FORM.CUSTOMSELECT WAS COMMENTED OUT {noteDropDownOptions ? (
              <Form.CustomSelect
                value={this.state.quickNote}
                onChange={(v) => {
                  if (v.target.value)
                    this.setState({ quickNote: v.target.value });
                }}
              >
                {noteDropDownOptions.map((note, index) => (
                  <option value={note} key={index}>
                    {note}
                  </option>
                ))}
                {this.state.quickNote == "" && (
                  <option value="">Choose Quick Note</option>
                )}
              </Form.CustomSelect>
            ) : (
              <FormControl
                aria-label="Channel Name"
                aria-describedby="inputGroup-sizing-default"
                value={this.state.quickNote}
                maxLength={50}
                placeholder={"Quick note max 50 characters"}
                onChange={(v) => {
                  this.setState({ quickNote: v.target.value });
                }}
              />
            )} END COMMENT}
            <Form.CustomSelect
              value={this.state.quickNote}
              onChange={(v) => {
                if (v.target.value)
                  this.setState({ quickNote: v.target.value });
              }}
            >
              {this.state.categories.map(({ name, id }) => (
                <option value={id} key={id}>
                  {name}
                </option>
              ))}
              {this.state.quickNote == "" && (
                <option value="">Choose Category</option>
              )}
            </Form.CustomSelect>
          </div>
          <div style={{ margin: 30 }}>
            <textarea
              className="form-control"
              value={this.state.largeNote}
              rows="5"
              placeholder={"Incident"}
              onChange={(v) => {
                this.setState({ largeNote: v.target.value });
              }}
            />
          </div>
          <div style={{ margin: 30 }}>
            <textarea
              className="form-control"
              value={this.state.consequence}
              rows="5"
              placeholder={"Consequence"}
              onChange={(v) => {
                this.setState({ consequence: v.target.value });
              }}
            />
          </div>
          <div style={{ margin: 30 }}>
            Consequence Fulfilled?
            <input type="checkbox" checked={this.state.consequenceFulfilled} onChange={e => this.setState({ consequenceFulfilled: !this.state.consequenceFulfilled })} />
          </div>
          <div style={{ margin: 30 }}>
            Parents Contacted?
            <input type="checkbox" checked={this.state.parentsContacted} onChange={e => this.setState({ parentsContacted: !this.state.parentsContacted })} />
          </div>
          {THIS DIV WAS COMMENTED OUT <div>
                    <input type="file" onChange={this.onFileChange} />
                    <button onClick={this.onFileUpload}>
                        Upload!
                    </button>
                </div>
                {this.fileData()} END COMMENT}
        </div>
      </div>
    );
  }
}

export default NewNote;

*/